import React from 'react';
import SEO from "../components/seo";

const NotFoundPage = () => (
    <div>
        <SEO title="Page Not Found"/>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
);

export default NotFoundPage;